function checkCookie() {
  var cookies = document.cookie.split(";");
  var cookie = cookies
    .map((cookie) => cookie.split("=")[0])
    .find((cookie) => cookie.includes("lette-akademie-dsgvo"));

  if (!cookie) {
    showCookieBanner();
  } else {
    hideCookieBanner();
  }
}

function hideCookieBanner() {
  var banner = document.querySelector(".cookie-banner");
  banner.style.display = "none";
}

function showCookieBanner() {
  var banner = document.querySelector(".cookie-banner");
  banner.style.display = "flex";
  banner.style.visibility = "visible";
}

export default checkCookie;
