function closeSignupForm() {
  const button = document.querySelector(".kurs__close-signup-form");
  if (!button) {
    return;
  }
  const signupForm = document.getElementById(
    "kurse__signup-form-overlay-wrapper"
  );

  button.addEventListener("click", () => {
    signupForm.classList.add("kurse__signup-form--hidden");
    signupForm.classList.remove("kurse__signup-form--visible");
    document.body.style.position = "relative";
  });
}

export default closeSignupForm;
