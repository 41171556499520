function burgerNav() {
  var burger = document.getElementsByClassName(
    "header-navigation__navigation-wrapper"
  );
  var burgerOverlay = document.querySelector(".header-navigation__nav-overlay");

  var burgerNavWrapper = document.querySelector(
    ".header-navigation__navigation"
  );
  var closeNav = document.querySelector(".header-navigation__burger");

  if (burger) {
    for (var i = 0; i < burger.length; i++) {
      burger[i].addEventListener("click", function () {
        document.body.classList.add("header-navigation__show");
      });
    }

    burgerOverlay.addEventListener("click", function () {
      document.body.classList.remove("header-navigation__show");
    });

    closeNav.addEventListener("click", function () {
      document.body.classList.remove("header-navigation__show");
    });

    burgerNavWrapper.addEventListener("click", function (e) {
      e.stopPropagation();
    });
  }
}

export default burgerNav;
