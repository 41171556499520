function toggleFilter() {
  const button = document.querySelector(".filter-options__toggle");
  if (!button) {
    return;
  }

  const filter = document.querySelector(".filter-options");
  button.addEventListener("click", () => {
    filter.classList.toggle("filter-options__filter--visible");
  });
}

export default toggleFilter;
