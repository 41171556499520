import burgerNav from "./burger-nav";
import checkCookie from "./cookie-banner";
import dsgvo from "./dsgvo";
import showSignupForm from "./show-signup-form";
import closeSignupForm from "./close-signup-form";
import toggleFilter from "./toggle-filter";
import flickitySlider from "./flickity-slider";

burgerNav();
checkCookie();
dsgvo();
showSignupForm();
closeSignupForm();
toggleFilter();
flickitySlider();

// function gallery() {
//   var galleryItem = document.getElementsByClassName("gallery__item");

//   if (galleryItem.length) {
//     var prevButton = document.getElementById("gallery__button--prev");
//     var nextButton = document.getElementById("gallery__button--next");
//     var activeImage = 0;
//     var lastImage = galleryItem.length - 1;

//     Object.values(galleryItem).map(function (item) {
//       if (item === galleryItem[0]) {
//         return item.classList.add("gallery__item--active");
//       }
//       return item;
//     });

//     nextButton.addEventListener("click", function () {
//       if (activeImage === lastImage) {
//         return;
//       }
//       var prevImage = activeImage + 1;
//       slideImages(activeImage, prevImage);
//       activeImage = prevImage;
//     });

//     prevButton.addEventListener("click", function () {
//       if (activeImage === 0) {
//         return;
//       }
//       var nextImage = activeImage - 1;
//       slideImages(activeImage, nextImage);
//       activeImage = nextImage;
//     });

//     function slideImages(activeImage, newImage) {
//       galleryItem[activeImage].classList.remove("gallery__item--active");
//       galleryItem[newImage].classList.add("gallery__item--active");
//     }
//   }
// }
// gallery();
