function dsgvo() {
  const button = document.querySelector(".cookie-banner__button");
  if (button) {
    button.addEventListener("click", () => {
      var d = new Date();
      d.setTime(d.getTime() + 5 * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      if (
        document.cookie.replace(
          /(?:(?:^|.*;\s*)lette-akademie\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ) !== "true"
      ) {
        document.cookie = "lette-akademie-dsgvo=true; " + expires + "; path=/";
        hideCookieBanner();
      }
    });
  }
}

function hideCookieBanner() {
  var banner = document.querySelector(".cookie-banner");
  banner.style.display = "none";
}
export default dsgvo;
