function showSignupForm() {
  const button = document.querySelector(".kurs__signup-button");
  if (!button) {
    return;
  }

  const signupForm = document.getElementById(
    "kurse__signup-form-overlay-wrapper"
  );

  button.addEventListener("click", () => {
    document.body.style.position = "fixed";
    signupForm.classList.add("kurse__signup-form--visible");
    signupForm.classList.remove("kurse__signup-form--hidden");
  });
}

export default showSignupForm;
