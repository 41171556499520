function flickity() {
  var galleries = Array.from(document.querySelectorAll(".main-carousel"));

  if (galleries.length) {
    galleries.forEach((elem) => {
      var flkty;
      if (elem.children.length === 1) {
        flkty = new Flickity(elem, {
          cellAlign: "center",
          contain: true,
          pageDots: false,
          prevNextButtons: false,
          imagesLoaded: true,
        });
      } else {
        flkty = new Flickity(elem, {
          cellAlign: "left",
          contain: true,
          imagesLoaded: true,
          prevNextButtons: false,
        });
      }
      return flkty;
    });
  }
}

export default flickity;
